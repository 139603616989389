.employment__container {
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.employment-img-container {
  position: relative;
}

.employment-img {
  position: absolute;
  right: -25px;
  top: -40px;
  width: 100%;
  /* height: auto; */
  min-height: 125%;
  /* width: 100%; */
  /* height: 100%; */
  /* max-width: 50%; */
  object-fit: cover;
}

a {
  text-decoration: none;
}