* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  -ms-overflow-style: none;  /* Hide scrollbar on IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar on Firefox */
}

*::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* page container and content wrap are used for the footer */

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
  background-color: lightgray;
}

.spacer {
  font-size: 0; 
  height: 150px;
  line-height: 0;
}

.spacer-md {
  font-size: 0; 
  height: 225px;
  line-height: 0;
}

.spacer-sm {
  font-size: 0; 
  height: 50px;
  line-height: 0;
}

.spacer-xs {
  font-size: 0; 
  height: 20px;
  line-height: 0;
}

h3.principle1 {
  position: relative;
}

.principle1::before {
  content: "";
  position: absolute;
  /* top: 20px; */
  left: 140px;
  bottom: -4px;
  width: 300px;
  height: 8px;
  transform: skew(-12deg) translateX(-50%);
  background: rgba(21,205,252,0.5);
  z-index: 999;
}

h3.principle2 {
  position: relative;
}

.principle2::before {
  content: "";
  position: absolute;
  /* top: 20px; */
  left: 50px;
  bottom: -4px;
  width: 125px;
  height: 8px;
  transform: skew(-12deg) translateX(-50%);
  background: rgba(21,205,252,0.5);
  z-index: 999;
}

h3.principle3 {
  position: relative;
}

.principle3::before {
  content: "";
  position: absolute;
  /* top: 20px; */
  left: 60px;
  bottom: -4px;
  width: 145px;
  height: 8px;
  transform: skew(-12deg) translateX(-50%);
  background: rgba(21,205,252,0.5);
  z-index: 999;
}

h3.principle4 {
  position: relative;
}

.principle4::before {
  content: "";
  position: absolute;
  /* top: 20px; */
  left: 60px;
  bottom: -4px;
  width: 145px;
  height: 8px;
  transform: skew(-12deg) translateX(-50%);
  background: rgba(21,205,252,0.5);
  z-index: 999;
}

.link-visted:visited {
  color: inherit;
}