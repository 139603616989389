.extra {
  padding: 2em;
}

.about__container {
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: rgba(220, 220, 220, 1);
  /* background-color: lightgray; */
}

.about-text-reg {
  font-size: 1rem;
}

.indent-level-1 {
  padding-left: 20px;
}

.about-img-main-container-v2 {
  position: relative;
}

.about-img-main-v2 {
  position: absolute;
  left: -25px;
  top: -25px;
  /* bottom: -25px; */
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  /* border-bottom-left-radius: 10px; */
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1115px) {
  .about-img-main-v2 { 
    min-height: 150% !important;
  }
}

@media screen and (max-width: 768px) {
  .about-img {
    object-fit: contain;
  }
}

.about-title-left {
  text-align: left;
}

.about-title-center {
  text-align: center;
}