.container__404 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /*background: linear-gradient(135deg, #03a9f4, #03a9f4 50%, #333 50%, #333);*/
    background: linear-gradient(135deg,  rgba(21,205,252,0.5),  rgba(21,205,252,0.5) 50%, rgba(30,30,30,1) 50%, rgba(30,30,30,1));
}

.box {
    position: relative;
}

.box h2 {
    color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15rem;
    text-shadow: -25px 25px 40px rgba(0,0,0,0.5),
        -10px 10px 0 rgba(255,255,255,0.5),
        -10px 20px 0 rgba(255,255,255,0.2),
        -10px 30px 0 rgba(255,255,255,0.05);
}

.box p {
    color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    /*text-shadow: -25px 25px 40px rgba(0,0,0,0.5),*/
    /*-10px 10px 0 rgba(255,255,255,0.5),*/
    /*-10px 20px 0 rgba(255,255,255,0.2),*/
    /*-10px 30px 0 rgba(255,255,255,0.05);*/
}

.box h2 .zero {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    background: linear-gradient(to bottom, #fff, #777);
    border-radius: 50%;
    /*margin-top: 0 15px;*/
    box-shadow: -25px 25px 40px rgba(0,0,0,0.5);
}

.box h2 .zero::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 180px;
    height: 180px;
    background: linear-gradient(to top, #fff, #777);
    border-radius: 50%;
}

.box h2 .zero::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    height: 100px;
    background: linear-gradient(315deg,  rgba(21,205,252,0.5),  rgba(21,205,252,0.5) 50%, rgba(30,30,30,1) 50%, rgba(30,30,30,1));
    border-radius: 50%;
    box-shadow: inset 0 0 30px rgba(0,0,0,1);
}
