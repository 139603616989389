:root {
  --accent-color: rgba(21,205,252,0.5);
}

ul {
  list-style-type: none;
  /*use padding to move list item from left to right*/
  padding-left: 1em;
}

ul li:before {
  content: "–";
  position: absolute;
  /*change margin to move dash around*/
  margin-left: -1em;
  color: var(--accent-color);
}

.services__container {
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.service-img-container {
  position: relative;
}

.services-img {
  position: absolute;
  left: -20px;
  top: -20px;
  width: 100%;
  /* height: auto; */
  min-height: 112%;
  /* width: 100%; */
  /* height: 100%; */
  /* max-width: 50%; */
  object-fit: cover;
}

.card {
  color: #fff;
  background-image: url(assets/Pipe1.jpg);
  background-size: cover;
  padding: 1rem 0 0;
  max-width: 35ch;
  border-radius: .5rem;
  overflow: hidden;

  transition: transform 500ms ease;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 0.3) 20%,
    hsl(0 0% 0% / 1)
  );
}

.card-title {
  position: relative;
  width: max-content;
  max-width: 100%;
  white-space:nowrap;
}

.card-title::after {
  content: "";
  position: absolute;
  height: 3px;
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: var(--accent-color);
  width: calc(100% + var(--padding));
  transform-origin: left;

  transition: transform 500ms ease;

}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-body {
  color: rgb(255 255 255 / 0.8);
  padding: .5rem;
}

@media (hover) {
  .card-content {
    transform: translateY(56%);
    transition: transform 500ms ease;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .card:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 1000ms;
  }

  .card-title::after {
    transform: scaleX(0);
  }

}

.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  background-color: var(--accent-color);
  color: #000;
  padding: .5em 1.25em;
  border-radius: .25rem;
  max-width: max-content;
}

.button > span {
  white-space: nowrap;
  max-width: 100%;
}

.button:hover,
.button:focus {
  background-color: lightgray;
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}