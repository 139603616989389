.cards {
  padding-top: 4rem;
  background: #fff;
}

.cards_about {
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__container_about {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  /*perspective: 1000px;*/
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

/*.fade-img {*/
/*  animation-name: fade-img;*/
/*  animation-duration: 2s;*/
/*}*/


.cards__item__pic-label {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__pic-label::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  right: 0;
  /*margin-left: 10px;*/
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #05D73E;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: transparent;
}

/*.cards__item__img:hover {*/
/*  transform: scale(1.1);*/
/*  -webkit-backface-visibility: hidden;*/
/*  backface-visibility: hidden;*/
/*}*/

.cards__service__title {
  text-align: center;
  padding: .5rem;
}

.cards__item__info {
  /*padding: 1rem 5rem;*/
  padding: 1rem 2rem;
  /*padding: 20px 30px 30px;*/
  /*padding: 2rem 3rem;*/
  /*background-color: #05D73E;*/
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  /*white-space: nowrap;*/
}

.cards__item__text_about {
  color: #252e48;
  font-size: 1.1rem;
  line-height: 24px;
  text-align: left;
  max-width: 550px;
  /*white-space: nowrap;*/
}

.flip-card {
  display: flex;
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /*transform: rotateY(540deg);*/
  /*-webkit-transform: rotateY(540deg);*/
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #fff;
  color: black;
  z-index: 2; /*FIXED FOR SAFARI -- PLACES FRONT ABOVE BACK SO WE DONT SEE BACK IMAGE BRIEFLY */
}

.flip-card-back {
  background-color: #fff;
  color: black;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /*transform: rotateY(-180deg);*/
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 80%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  perspective: 1000px;
  padding: 30px;
}

.space {
  margin: 5px;
}

/*@media screen and (max-width: 960px) {*/
/*  .flip-card:active .flip-card-inner {*/
/*    transform: rotateY(180deg);*/
/*  }*/
/*}*/

/*@media (hover: hover){*/
/*  .flip-card:hover .flip-card-inner {*/
/*    transform: rotateY(180deg);*/
/*    -webkit-transform: rotateY(180deg);*/
/*  }*/
/*}*/

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

/*@media only screen and (min-width: 1025px) {*/
/*  .cards__items {*/
/*    display: flex;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 1025px) {*/
/*  .cards__item {*/
/*    margin-bottom: 2rem;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 1025px) {*/
/*  .cards__items {*/
/*    display: flex;*/
/*  }*/
/*}*/

@media only screen and (min-width: 1180px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


