/* video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
} */

.hero-container {
  /*background: url('/images/img-home.jpg') center center/cover no-repeat;*/
  /*background: url('/images/landingLogo.jpg') center center/cover no-repeat;*/
  /*background: url('/images/landing.jpg') center center/cover no-repeat;*/
  /* background: url('https://jarrenj.github.io/websterslawncare/images/landing.jpg') center center/cover no-repeat; */
  background: url('/src/assets/Pipe1.jpg') center center/cover no-repeat;
  background-size: cover;
  height: 100vh;
  /*height: 50vh;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.35);
  /* object-fit: cover; */
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container-title {
  color: #fff;
  font-size: 5rem;
  margin-top: -100px;
  text-align: center;
}

.hero-container-sub-title {
  color: #fff;
  font-size: 2.0rem;
  margin-top: -100px;
  text-align: center;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-container-small {
  margin-top: 8px;
  padding-left: 50px !important;
  padding-right: 50px !important;
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 1130px) {
  .hero-container-title {
    font-size: 4rem;
  }

  .hero-container-small {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 914px) {
  .hero-container-title {
    font-size: 3rem;
  }

  .hero-container-small {
    font-size: 1.1rem;
    padding-left: 125px !important;
    padding-right: 125px !important;
  }
}

@media screen and (max-width: 699px) {
  .hero-container-title {
    font-size: 2.4rem;
  }

  .hero-container-small {
    font-size: 1.1rem;
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

@media screen and (max-width: 552px) {
  .hero-container-title {
    font-size: 2.3rem;
  }

  /* .hero-container-small {
    font-size: 1.1rem;
    padding-left: 80px !important;
    padding-right: 80px !important;
  } */
}

@media screen and (max-width: 460px) {
  .hero-container-title {
    font-size: 1.8rem;
  }

  /* .hero-container-small {
    font-size: 1.1rem;
    padding-left: 80px !important;
    padding-right: 80px !important;
  } */
}

@media screen and (max-width: 390px) {
  .hero-container-title {
    font-size: 1.6rem;
  }

  .hero-container-small {
    font-size: 1.05rem;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media screen and (max-width: 375px) {
  .hero-container-title {
    font-size: 1.5rem;
  }

  .hero-container-small {
    font-size: 1.01rem;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}

@media screen and (max-width: 960px) {
  /* .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  } */
}

@media screen and (max-width: 768px) {
  /* .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  } */

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
