.extra {
  padding: 2em;
}

.about__container {
  padding: 2rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.about-text-reg {
  font-size: 1rem;
}

.indent-level-1 {
  padding-left: 20px;
}

.about-img-main-container {
  position: relative;
}

.about-img-main {
  position: absolute;
  left: -20px;
  top: -20px;
  width: 100%;
  height: 120%;
  object-fit: cover;
  /* border-bottom-left-radius: 10px; */
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .about-img {
    object-fit: contain;
  }
}

.about-title-left {
  text-align: left;
}

.about-title-center {
  text-align: center;
}